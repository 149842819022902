.loginContainer {
  width: 300px;
  height: 200px;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.logo {
  color: tomato;
  display: flex;
  align-items: center;
  font-weight: 700;
}

.loginBtn {
  border: none;
  padding: 5px;
  border-radius: 5px;
  color: white;
  background-color: rgb(174, 183, 6);
  cursor: pointer;
}

.failure {
  color: red;
  font-size: 12px;
  text-align: center;
}

.loginCancel {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}